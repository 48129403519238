

export const stripHtml = (htmlString) => {
  if (!htmlString) return "";
  const tmp = document.createElement("DIV");
  tmp.innerHTML = htmlString;
  return tmp.textContent || tmp.innerText || "";
};


export const convertTextToUnderscore = (text) => {
  if (!text) return "none";
  return text
    .toLocaleLowerCase()
    .replace(/[^A-Z0-9\-]+/gi, "-")
    .substring(0, 50);
};

export const compressFormData = (data, objKey, fileKey) => {
  const fileValue = data[fileKey];
  const newData = {...data}
  if(!fileValue || !(fileValue instanceof File)) {
    delete newData[fileKey]
  }
  const formData = new FormData();
  Object.keys(newData).map((key) => {
    if (Array.isArray(data[key])) {
      data[key].map((val) => {
        if(val) {
          formData.append(`${objKey}[${key}][]`, val);
        }
      })
    } else {
      formData.append(`${objKey}[${key}]`, data[key] || '');
    }
  })
  // formData.append(`${objKey}[${fileKey}]`, data[fileKey])
  return formData;
}


export const convertJsonToParams = (data) => {
  const parameters = [];
  Object.keys(data).map((k) => {
    if (Array.isArray(data[k])) {
      data[k].map((e) =>
        parameters.push(
          //eslint-disable-next-line
          encodeURIComponent(`${k}[]`) + "=" + encodeURIComponent(e || "") 
        )
      );
    } else if (data[k] && typeof data[k] === "object") {
      // console.log(data[k])
      Object.keys(data[k]).map((key1) =>
        parameters.push(
            //eslint-disable-next-line
            encodeURIComponent(`${k}[${key1}]`) + '=' + encodeURIComponent(data[k][key1] || "")
        )
      );
    } else {
      parameters.push(
        //eslint-disable-next-line
        encodeURIComponent(`${k}`) + '=' + encodeURIComponent(data[k] || "")
      );
    }
    return null;
  });
  return parameters.join("&");
};

// ==================================
// MAPs Utils
// ==================================
const parseStreet = (result, useShortName = true) => {
  const address_components = result.address_components;
  const streetNumber = address_components.find(({ types }) =>
    types.includes("street_number")
  );

  const streetName = address_components.find(({ types }) =>
    types.includes("route")
  );

  return [streetNumber, streetName]
    .filter((obj) => !!obj)
    .map((obj) => (useShortName ? obj.short_name : obj.long_name))
    .join(" ");
};

const parseStreet2 = (result, useShortName = true) => {
  const address_components = result.address_components;
  const subpremise = address_components.find(({ types }) =>
    types.includes("subpremise")
  );
  if (!subpremise) return null;
  return useShortName ? subpremise.short_name : subpremise.long_name;
};

const parseCity = (result, useShortName = true) => {
  const address_components = result.address_components;
  let city = address_components.find(
    ({ types }) => types.includes("locality") && types.includes("political")
  );
  if (!city) {
    const province = address_components.find(
      ({ types }) =>
        types.includes("administrative_area_level_1") &&
        types.includes("political")
    );
    if (province) {
      city = address_components.find(
        ({ types }) =>
          types.includes("administrative_area_level_2") &&
          types.includes("political")
      );
    }
  }

  if (!city) return null;

  return useShortName ? city.long_name : city.long_name;
};

const parseProvince = (result, useShortName = true) => {
  const address_components = result.address_components;
  let province = address_components.find(
    ({ types }) =>
      types.includes("administrative_area_level_1") &&
      types.includes("political")
  );
  if (!province) {
    province = address_components.find(
      ({ types }) =>
        types.includes("administrative_area_level_2") &&
        types.includes("political")
    );
  }

  if (!province) return null;

  return useShortName ? province.short_name : province.long_name;
};

const parsePostalCode = (result, useShortName = true) => {
  const address_components = result.address_components;
  const postalCode = address_components.find(({ types }) =>
    types.includes("postal_code")
  );
  if (!postalCode) return null;

  return useShortName ? postalCode.short_name : postalCode.long_name;
};

const parseCountry = (result, useShortName = true) => {
  const address_components = result.address_components;
  const country = address_components.find(
    ({ types }) => types.includes("country") && types.includes("political")
  );
  if (!country) return null;

  return useShortName ? country.short_name : country.long_name;
};

export const mapParser = {
  street: parseStreet,
  street2: parseStreet2,
  city: parseCity,
  province: parseProvince,
  postalCode: parsePostalCode,
  country: parseCountry,
};
