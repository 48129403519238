import React, { useState, useEffect, useRef } from "react";
import { Formik, useFormik } from "formik";

import * as Yup from "yup";
import { FormGroup, InputGroup, Input, Label, FormText, FormFeedback, Button, ButtonGroup } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import { compressFormData } from "./mixins/sharedUtils";

import ArticleForm from "./ArticleForm";

const blogScheme = Yup.object().shape({
  title: Yup.string().required("Required"),
  // image_url: Yup.string().required("Required"),
  content: Yup.string().required("Required"),
  // author_name: Yup.string(),
});



const ArticleEdit = (props) => {
  const { article = {}, blogTypes = [], authors = []} = props;
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef();
  const handleSave = (data) => {
    const tag_list = (data.tag_list || []).map(({name})=>(name)).join(',');    

    console.log('handleSave', data)
    setIsLoading(true);
    const formData = compressFormData({...data, tag_list}, 'article', 'image'); 
    console.log('formData', formData)
    fetch(`/admin/articles/${article.id}.json`, {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        // 'Content-Type': 'multipart/form-data',        
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          ?.content,
      },
      body: compressFormData({...data, tag_list}, 'article', 'image'),
    })
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        console.log("resp", resp);
        toast("Updated article successfully!")

        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(`Sorry! It got error: ${error.message}`)

        setIsLoading(false);
      });
  }
  const formik = useFormik({
    initialValues: {
      ...(article || {}),
    },
    validationSchema: blogScheme, // replace with your actual validation schema
    onSubmit: handleSave, // replace with your form submission function
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  return (
    <div>
      
        <form onSubmit={handleSubmit} ref={formRef}>
          <ArticleForm
            {...formik}
            blogTypes={blogTypes}
            authors={authors}
          />
          <ButtonGroup>
            <Button type="submit" disabled={isLoading}>Submit</Button>
            <Button color="info" tag={`a`} href={`/articles/${article.slug}?preview=1`} target="_blank" disabled={isLoading}>Preview</Button>
          </ButtonGroup>
          </form>
        
    </div>
  )
}

export default ArticleEdit;
