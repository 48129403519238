import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import Uppy from "@uppy/core";

import Webcam from "@uppy/webcam";
import { Dashboard } from "@uppy/react";
import XHR from "@uppy/xhr-upload";
import { Gallery } from "react-grid-gallery";


const uppy1 = new Uppy({
  inline: true,
  meta: { type: "avatar" },
  restrictions: {
    maxFileSize: 10048576,
    maxNumberOfFiles: 5,
    allowedFileTypes: ["image/*"],
  },
  locale: {
    strings: {
      poweredBy: "Powered by Swanpass",
    },
  },
})
  .use(Webcam)
  .use(XHR, {
    endpoint: "/photos",
    formData: true,
    fieldName: "image",
    headers: {
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
        ?.content,
    },
  });

const GalleryModal = (props) => {
  const { maxNumberOfFiles, ...restProps} = props;
  const [filters, setFilters] = useState({query: '', page: 1});
  const [modal, setModal] = useState(props.isOpen);
  const [maxOfFiles, setMaxOfFiles] = useState(maxNumberOfFiles || 10);
  const [activeTab, setActiveTab] = useState("libs");
  const [photos, setPhotos] = useState([])
  const [meta, setMeta] = useState({})

  const [selectedPhotos, setSelectedPhotos] = useState([]);

  const handleSelect = (e) => {
    e.preventDefault();
    if(props.onSelectPhoto) {
      props.onSelectPhoto([...selectedPhotos]);
      setSelectedPhotos([]);
    }
  };

  const fetchPhotos = async (params = {}) => {
    const query = params.query || '';
    const page = params.page || 1;
    const resp = await fetch(`/photos.json?query=${query}&page=${page}`);
    const data = await resp.json();
    setPhotos(data.photos)
    setMeta(data.meta)
  }

  const handleBeforeUpload = (file) => {
    if (file.type.includes('image')) {
      const image = new Image();
      image.src = window.URL.createObjectURL(file.data);
      image.onload = () => {
        const resolution = {
          width: image.width,
          height: image.height,
        };
        console.log('resolution', resolution);
        uppy1.setFileMeta(file.id, {
          width: resolution.width,
          height: resolution.height,
          resolution: resolution,
        });
      };
    }
  };

  uppy1.on('file-added', handleBeforeUpload);


  uppy1.on('upload-success', (file, response) => {
    // File uploaded successfully
    console.log('File uploaded:', file, file.name, response);
    const newPhotos = selectedPhotos.filter((d) => (d.id != response.body.id))
    setSelectedPhotos([...newPhotos, response.body])
  });

  uppy1.on("complete", (result) => {
    // All files uploaded successfully
    console.log("All files uploaded:", result.successful);
    fetchPhotos();
    setActiveTab("libs");
  });

  useEffect(() => {
    fetchPhotos();
  }, []);
  useEffect(() => {
    setMaxOfFiles(props.maxNumberOfFiles)
    uppy1.setOptions({
      restrictions: { maxNumberOfFiles: props.maxNumberOfFiles },
    });
  
  }, [props.maxNumberOfFiles]);

  const handleCancel = (e) => {
    e.preventDefault();
    props.onCancel()
  }

  const handleChange = (e) => {
    const newFilters = {...filters, query: e.target.value};
    setFilters({...newFilters})
    fetchPhotos(newFilters);
  }
  const handleSelectItem = (index, photo, event) => {
    const isSelected = selectedPhotos.find((x) =>(x.id === photo.id));
    if(isSelected) {
      const newPhotos = selectedPhotos.filter((x) =>(x.id !== photo.id));
      setSelectedPhotos([
        ...newPhotos
      ]);
    } else {
      const newPhotos = [...selectedPhotos, photo]
      setSelectedPhotos(newPhotos.slice(newPhotos.length - maxNumberOfFiles, newPhotos.length))
    }
  }
  const images = photos.map((photo) => ({
    src: photo.image_url,
    image_url: photo.image_url,
    height: photo.height,
    width: photo.width,
    caption: photo.caption || photo.file_name,
    id: photo.id,
    isSelected: selectedPhotos.find((x) =>(x.id === photo.id))
  }))
  return (
    <Modal
      className="gallery-modal"
      fullscreen
      isOpen={modal}
      toggle={handleCancel}      
      keyboard={false}
      {...restProps}
      zIndex={9999}
    >
      <ModalHeader toggle={e => props.onCancel()}>Media Library</ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              href="#"
              className={`${activeTab === "upload" ? "active" : ""}`}
              onClick={(e) => setActiveTab("upload")}
            >
              Upload files
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#"
              className={`${activeTab !== "upload" ? "active" : ""}`}
              onClick={(e) => setActiveTab("libs")}
            >
              Library
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="upload">
            <Dashboard
              uppy={uppy1}
              // hideUploadButton={true}
              proudlyDisplayPoweredByUppy={false}
              plugins={["Webcam"]}
            />
          </TabPane>
          <TabPane tabId="libs">
            <input type="text" name="query" className="form-control" placeholder="file name or caption" onChange={handleChange}/>

            <Gallery images={images} onSelect={handleSelectItem}  />

           
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        {
          selectedPhotos.length >= maxOfFiles &&  <div className="primary-text-color">
          You can only upload/select {maxOfFiles} {maxOfFiles > 1 ? 'files' : 'file'}
        </div>
        }
        <a href="#" onClick={handleCancel}>
          Cancel
        </a>
        <button className="btn btn-primary"
          disabled={selectedPhotos.length === 0}
          onClick={handleSelect}
        >
          Select
        </button>{" "}
       
      </ModalFooter>
    </Modal>
  );
};


export default GalleryModal;
