import React, { useState, useEffect, useRef } from "react";
import { Formik, useFormik } from "formik";

import * as Yup from "yup";
import { FormGroup, InputGroup, Input, Label, FormText, FormFeedback, Button } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import { compressFormData } from "./mixins/sharedUtils";

import ArticleForm from "./ArticleForm";

const blogScheme = Yup.object().shape({
  title: Yup.string().required("Required"),
  // image_url: Yup.string().required("Required"),
  content: Yup.string().required("Required"),
  // author_name: Yup.string(),
});



const ArticleEdit = (props) => {
  const { article = {}, blogTypes = [], authors = []} = props;
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef();
  const handleSave = (data) => {
    const tag_list = (data.tag_list || []).map(({name})=>(name)).join(',');    

    setIsLoading(true);
    fetch(`/admin/articles.json`, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          ?.content,
      },
      body: compressFormData({...data, tag_list}, 'article', 'image'),
    })
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        console.log("resp", resp);
        toast("Added article successfully!")
        setIsLoading(false);
        window.location.href = `/admin/articles/${resp.slug}`
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(`Sorry! It got error: ${error.message}`)
        setIsLoading(false);
      });
  }
  const formik = useFormik({
    initialValues: {
      ...(article || {}),
    },
    validationSchema: blogScheme, // replace with your actual validation schema
    onSubmit: handleSave, // replace with your form submission function
  });

  const {
    handleSubmit,
  } = formik;

  return (
    <div>
      
        <form onSubmit={handleSubmit} ref={formRef}>
          <ArticleForm
            {...formik}
            blogTypes={blogTypes}
            authors={authors}

          />
          <Button type="submit" disabled={isLoading}>Submit</Button>
          </form>
          <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          />
          {/* Same as */}
          <ToastContainer />        
    </div>
  )
}

export default ArticleEdit;
