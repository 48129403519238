import React, { useState, useEffect, useRef, Fragment } from "react";
import { Formik, useFormik } from "formik";

import * as Yup from "yup";
import { FormGroup, InputGroup, Input, Label, FormText, FormFeedback, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { ToastContainer, toast } from 'react-toastify';

import { stripHtml } from "./mixins/sharedUtils";
import TinyEditor from "./mixins/TinyEditor";

import 'react-toastify/dist/ReactToastify.css';
import SeoForm from "./SeoForm";

const ArticleForm = (props) => {
  const { 
    blogTypes,
    authors,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = props;
  return (
    <Fragment>  
        <FormGroup>
          <Label for="image">
            Image 
            <sup image="required">*</sup>
            {
              values.image?.url && <a href={`${values.image?.url}`} target="_blank">
                &nbsp;
                <img src={`${values.image?.url}`}   width="30" />  
              </a>
            }
            
          </Label>
        
          <Input 
            type="file"
            name="image" 
            placeholder="Featured image" 
            // value={values.image ? values.image : null} 
            accept="image/*"
            onChange={(event) => setFieldValue('image', event.target.files[0])}
            valid={!errors.image}
            invalid={!!errors.image}
          />
          <FormFeedback>
            Featured image is required
          </FormFeedback>
          <FormText>
            Example help text that remains unchanged.
          </FormText>
        </FormGroup>
        <FormGroup>
          <Label for="title">
            Blog type <sup title="required">*</sup>
          </Label>
          <Input
              name="blog_type_id"
              type="select"
              value={`${values.blog_type_id || ''}`} 
              onChange={handleChange}              
          >
            {blogTypes.map((bt) => (
              <option value={bt.id}>{bt.name}</option>
            ))}
          </Input>
          <FormFeedback>
            Type is required
          </FormFeedback>
          <FormText>
            Example help text that remains unchanged.
          </FormText>
        </FormGroup>
        <FormGroup>
          <Label for="title">
            Title <sup title="required">*</sup>
          </Label>
          <Input 
            name="title" 
            placeholder="Title of article" 
            value={`${values.title || ''}`} 
            onChange={handleChange}
            valid={!errors.title}
            invalid={!!errors.title}
            required
          />
          <FormFeedback>
            Title is required
          </FormFeedback>
          <FormText>
            Example help text that remains unchanged.
          </FormText>
        </FormGroup>

        <FormGroup>
          <Label for="excerpt">
            Excerpt
          </Label>
          <Input 
            type="textarea" 
            name="excerpt"
            placeholder="Short description content (2-3 sentences)" 
            value={`${values.excerpt || ''}`} 
            onChange={handleChange}
            valid={!errors.excerpt}
            invalid={!!errors.excerpt}          
          />
          <FormFeedback>
            You will not be able to see this
          </FormFeedback>
          <FormText>
            This field appears in listing page. Default is 250 chars from stripped content. 
          </FormText>
        </FormGroup>
        
        <FormGroup>
          <Label for="permalink">
            Permalink
          </Label>
          <Input type="text" 
            name="slug" 
            placeholder="eg path-to-article" 
            value={`${values.slug || ''}`} 
            onChange={handleChange}
            valid={!errors.slug}
            invalid={!!errors.slug}          
          />
          <FormFeedback>
            You will not be able to see this
          </FormFeedback>
          <FormText>
            Leave blank if don't want to customize the link
          </FormText>
        </FormGroup>
      
          <FormGroup>
            <Label for="content">
              Content
            </Label>
            <input 
              type="text" 
              style={{width: 0, height: 0, opacity: 0}} 
              value={`${stripHtml(values.content || '')}`}    
              required
              onChange={e => console.log(e)}
            />
            <TinyEditor
              initialValue={`${values.content || ''}`}
              onChange={(val) => setFieldValue('content', val)}
            />
            <FormFeedback valid>
              Sweet! that name is available
            </FormFeedback>
    
          </FormGroup>
          <FormGroup check>
            <Input type="checkbox" 
              name="is_public"
              id="is_public"
              checked={values.is_public && true}
              onChange={(event) => {
                setFieldValue('is_public', event.target.checked)
              }}
            />
            {' '}
            <Label check for="is_public"  className="text-danger">
            Published?
            </Label>
          </FormGroup>
              
        <FormGroup style={{display: values.is_public ? 'block' : 'none'}}>
        <Label for="published_at">
              Published Time
          </Label>
            <Flatpickr
            className="form-control"
            data-enable-time
            value={values.published_at ? values.published_at : ''}
            onChange={([date]) => {
              setFieldValue('published_at', date)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="title">
            Assign to author <sup title="required">*</sup>
          </Label>
          <Input
              name="author_id"
              type="select"
              value={`${values.author_id || ''}`} 
              onChange={handleChange}              
          >
            {authors.map((author) => (
              <option value={author.id}>{author.name}</option>
            ))}
          </Input>
          <FormFeedback>
            Type is required
          </FormFeedback>
          <FormText>
            Example help text that remains unchanged.
          </FormText>
        </FormGroup>
          <SeoForm
          {...props}
        />
      
        <ToastContainer />
  
    </Fragment>
  )
}

export default ArticleForm;
