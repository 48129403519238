import React, { useState, useEffect, useRef, Fragment } from "react";

import { Editor } from "@tinymce/tinymce-react";

import GalleryModal from "./GalleryModal";

const TinyEditor = (props) => {
  const { initialValue = '', onChange} = props;
  const [value, setValue] = useState(initialValue ?? '');

  const [openingModal, setOpeningModal] = useState(null);
  const editorRef = useRef(null);
  const handleEditorChange = (newValue, editor) => {
    setValue(newValue)
    if(onChange) {
      onChange(newValue);
    }
  }
  const handleFilePicker = (callback, value, meta) => {
    // Open the modal when the user wants to insert an image
    setOpeningModal(true);

    // Set TinyMCE callback to handle the selected image
    window.handleImageSelectCallback = (imageUrl) => {
      callback(imageUrl);
      setOpeningModal(false);
    };
  };
  const handleSelect = (images) => {
    if (editorRef.current) {
      console.log('handleSelect', images)
      window.handleImageSelectCallback(images ? images[0].image_url : null, {});
      // editorRef.current.editorUpload.uploadImages();
    }

    setOpeningModal(null);
  };
  const handleImageUpload = async (blobInfo, success, failure) => {
    return new Promise((resolve, reject) => {
      if (blobInfo.blob() instanceof Blob) {
        const image = new Image();
        image.src = URL.createObjectURL(blobInfo.blob());

        image.onload = () => {
          console.log(blobInfo, image.width, image.height, success, failure);
          // Check image dimensions
          if (image.width < 100 || image.height < 100) {
            reject("Image dimensions must be at least 100x100 pixels");
          } else {
            // Your custom image upload logic here
            // You can use libraries like axios to make a POST request to your server
            // Example using fetch:
            const formData = new FormData();
            formData.append("size", blobInfo.blob().size);
            formData.append("type", blobInfo.blob().type);
            formData.append("name", blobInfo.filename());
            formData.append("image", blobInfo.blob());
            fetch("/photos", {
              method: "POST",
              body: formData,
              headers: {
                "X-CSRF-Token": document.querySelector(
                  'meta[name="csrf-token"]'
                )?.content,
              },
            })
              .then((response) => response.json())
              .then((data) => {
                console.log(data);
                resolve(data.image_url);
              })
              .catch(() => reject("Error uploading image"));
          }
        };

        image.onerror = () => {
          reject("Error loading image");
        };
      } else {
        reject("No valid image selected");
      }
    });
  };
  useEffect(() => {
    if(value != initialValue) {
      setValue(initialValue ?? '')
    }
  }, [props.initialValue]);
  return (
    <Fragment>
      <Editor
        // apiKey="t6ea1r735spd1c8dldi10clhte2g654zc5ndpatlixdkbn5h"
        tinymceScriptSrc={'/static/tinymce/tinymce.min.js'}
        init={{
          plugins:
            "fullscreen ai tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss",
          toolbar:
            "fullscreen | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
          tinycomments_mode: "embedded",
          fullscreen_native: true,
          tinycomments_author: "Author name",
          mergetags_list: [
            { value: "First.Name", title: "First Name" },
            { value: "Email", title: "Email" },
          ],
          ai_request: (request, respondWith) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
          images_upload_url: "/photos",
          automatic_uploads: true,
          // images_reuse_filename: true,
          external_image_list_url: "/photos.js",
          a11y_advanced_options: true,
          image_advtab: true,
          image_caption: true,
          images_upload_handler: handleImageUpload,
          file_picker_callback: handleFilePicker,
        }}
        // initialValue={initialValue}
        value={value}
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        onEditorChange={handleEditorChange}
      />
      <GalleryModal
        maxNumberOfFiles={1}
        isOpen={!!openingModal}
        onCancel={(e) => setOpeningModal(null)}
        onSelectPhoto={handleSelect}
      />
    </Fragment>
  );
};

export default TinyEditor;
