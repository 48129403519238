import React, { useState, useEffect, useRef, Fragment } from "react";

import { FormGroup, InputGroup, Input, Label, FormText, FormFeedback, Button } from "reactstrap";


const SeoForm = (props) => {
  const { 
    values,
    errors,
    handleChange,
  } = props;
  return (
    <Fragment>        
        <FormGroup>
          <Label for="seoTitle">
            SEO Title
          </Label>
          <Input type="text" 
            name="seo_title" 
            placeholder="SEO meta title" 
            value={`${values.seo_title || ''}`} 
            onChange={handleChange}
            valid={!errors.seo_title}
            invalid={!!errors.seo_title}          
          />
          <FormFeedback>
            You will not be able to see this
          </FormFeedback>
          <FormText>
            Maximum 125 chars 
          </FormText>
        </FormGroup>
        <FormGroup>
          <Label for="seoDescription">
            SEO Description
          </Label>
          <Input type="textarea" 
            name="seo_descr" 
            placeholder="SEO meta description" 
            value={`${values.seo_descr || ''}`} 
            onChange={handleChange}
            valid={!errors.seo_descr}
            invalid={!!errors.seo_descr}
          />
          <FormFeedback>
            Maximum 250 chars 
          </FormFeedback>
          <FormText>
            Maximum 250 chars 
          </FormText>
        </FormGroup>
        <FormGroup>
          <Label for="seoKeywords">
            SEO Keywords
          </Label>
          <Input type="text" 
            name="seo_keywords" 
            placeholder="SEO Keyword 1, keyword 2,..." 
            value={`${values.seo_keywords || ''}`} 
            onChange={handleChange}
            valid={!errors.seo_keywords}
            invalid={!!errors.seo_keywords}          
          />
          <FormFeedback>
            You will not be able to see this
          </FormFeedback>
          <FormText>
            Keywords will be shown on google search.
          </FormText>
        </FormGroup>
      

    </Fragment>
  )
}

export default SeoForm;
